import React from "react";
import { useSelector } from "react-redux";

const Welcome = () => {
 
    var myDate = new Date(Date.now()); 
    var hours= myDate.getHours();
    var greet;

  const { user } = useSelector((state) => state.auth);

if (hours < 10)
  greet =  "pagi";
else if (hours >= 10 && hours <= 14)
  greet = "siang";
else if (hours >= 14 && hours <= 18)
  greet = "sore";
else if (hours >= 18 && hours <= 24)
  greet = "malam";

  return (
    <div className="p-5 mt-6 mb-2 mr-1" style={{borderRadius: '5px'}}>
      <h2 className="subtitle"><b>Dashboard</b></h2>
      <div className="box">
      <h2 className="subtitle">
        Selamat {greet} <strong>{user && user.name}</strong>
      </h2>
      <p> Selamat datang di Sistem Layanan <b>Balance Inquiry</b> PT BPR BANK BOYOLALI (PERSERODA)</p>
      </div>
    </div>
  );
};

export default Welcome;







