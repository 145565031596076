import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from 'react-data-table-component';
import { IoPencil, IoTrash, IoEllipsisVertical } from "react-icons/io5";
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from './Utils';
import env from "react-dotenv";

const Userlist = () => {
  const [users, setUsers] = useState([]);
  const [filteredUser, setFilteredUser] = useState([])
  const [name, setName] = useLocalStorage('name', '')
  const [token, setToken] = useState('')
  const [expire, setExpire] = useState('')
  const axiosJWT = axios.create()

  const [search, setSearch] = useState('')
  const [msg, setMsg] = useState("");

  const [idedit, setIdEdit] = useState("");
  const [kodeuseribs, setKodeUserIbs] = useState("");
  const [nameedit, setNameEdit] = useState("");
  const [email, setEmail] = useState("");
  const [perkkas, setPerkKas] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [terminalid, setTerminalID] = useState("");
  const [kodekantor, setKodeKantor] = useState("");
  const [namaperk, setNamaPerk] = useState("");

  const [activeRow, setActiveRow] = useState(null);
  const [isOpen, setIsOpen] = useState(false);


  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const toggleDropdown = (rowId) => {
    setActiveRow(rowId);
    setIsOpen(!isOpen);
  };

  
  const resetForm = () => {
    setIdEdit("")
    setKodeUserIbs("")
    setNameEdit("")
    setEmail("")
    setPerkKas("")
    setPassword("")
    setConfPassword("")
    setRole("")
    setStatus("")
    setTerminalID("")
    setKodeKantor("")
    setNamaPerk("")
  }

  // const resetFormEdit = () => {
  //   setPassword("")
  //   setConfPassword("")
  //   setTerminalID("")
  //   setKodeKantor("")
  // }

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    const result = users.filter((users) => {
        return users.name.toLowerCase().match(search.toLowerCase())
    })
    setFilteredUser(result)
}, [search])

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${env.API_URL}/token`)
        config.headers.Authorization = `Bearer ${response.data.accessToken}`
        setToken(response.data.accessToken)
        const decoded = jwt_decode(response.data.accessToken)
        setName(decoded.name)
        setExpire(decoded.exp)
    }
    return config
}, (error) => Promise.reject(error))

  const columns = [
    {
        name: 'No',
        selector: (row, index) => index + 1,
        sortable: false,
        width: "50px",
        headerClassName: 'column-no-header'
    },
    {
        name: 'Id IBS',
        selector: row => row.kode_user_ibs,
        sortable: true
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true
    },
    {
        name: 'Role',
        selector: row => row.role,
        sortable: true
    },
    {
        name: 'Perkiraan Kas',
        selector: row => row.perk_kas,
        sortable: true
    },
    {
      name: 'Terminal ID',
      selector: row => row.terminal_id,
      sortable: true
    },
    {
      name: 'Kode Kantor',
      selector: row => row.kode_kantor,
      sortable: true
    },
    {
        name: 'Action',
        sortable: false,
        selector: row => row.uuid,
        cell: (row) => [
            <div className={`dropdown ${isOpen && activeRow === row.uuid ? 'is-active' : ''}`}>
            <div className="dropdown-trigger">
              <div className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={() => toggleDropdown(row.uuid)}>
                  <IoEllipsisVertical />
              </div>
            </div>
            <div className="dropdown-menu" id="dropdown-menu" role="menu">
              <div className="dropdown-content">
                <a href="#" onClick={() => {setIdEdit(row.uuid);getUserById(row.uuid);handleOpenModal();resetForm()}} className="dropdown-item"><IoPencil /> Edit</a>
                <a href="#" onClick={() => {deleteUser(row.uuid)}} className="dropdown-item"><IoTrash /> Delete</a>
              </div>
            </div>
          </div>
            
          ]
    }
  ];


const getUsers = async () => {
    const response = await axiosJWT.get(`${env.API_URL}/users`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    })
    setUsers(response.data)
    setFilteredUser(response.data)
}

const getUserById = async (uuid) => {
  try {
    const response = await axiosJWT.get(`${env.API_URL}/users/${uuid}`,{
      headers: {
        Authorization: `Bearer ${token}`
    }
    });
    setIdEdit(response.data.uuid);
    setKodeUserIbs(response.data.kode_user_ibs);
    setNameEdit(response.data.name);
    setEmail(response.data.email);
    setPerkKas(response.data.perk_kas);
    setRole(response.data.role);
    setStatus(response.data.flag);
    setTerminalID(response.data.terminal_id);
    setKodeKantor(response.data.kode_kantor);
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};

const saveUser = async (e) => {
  e.preventDefault();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
if(idedit == ''){
if(kodeuseribs == ''){
  Swal.fire(
    'Gagal!',
    'Id user IBS wajib di isi!',
    'error'
)
}else if (nameedit == ''){
  Swal.fire(
    'Gagal!',
    'Nama user wajib di isi!',
    'error'
)
}else if (email == ''){
  Swal.fire(
    'Gagal!',
    'Email user wajib di isi!',
    'error'
)
}else if (!emailRegex.test(email)){
  Swal.fire(
    'Gagal!',
    'Invalid format email!',
    'error'
)
}else if (password == ''){
  Swal.fire(
    'Gagal!',
    'Password wajib di isi!',
    'error'
)
}else if (confPassword == ''){
  Swal.fire(
    'Gagal!',
    'Konfirmasi password wajib di isi!',
    'error'
)
}else if (password !== confPassword){
  Swal.fire(
    'Gagal!',
    'Password dan konfirmasi password tidak sama!',
    'error'
)
}else if (role == ''){
  Swal.fire(
    'Gagal!',
    'Role user wajib di isi!',
    'error'
)
}else if (status == ''){
  Swal.fire(
    'Gagal!',
    'Status user wajib di isi!',
    'error'
)
}else if (terminalid == ''){
  Swal.fire(
    'Gagal!',
    'Terminal ID wajib di isi!',
    'error'
)
}else if (kodekantor == ''){
  Swal.fire(
    'Gagal!',
    'Kode Kantor wajib di isi!',
    'error'
)
}else{
    await axiosJWT.post(`${env.API_URL}/users`, {
      kode_user_ibs:kodeuseribs,
      name: nameedit,
      email: email,
      perk_kas: perkkas,
      nama_perk_kas: namaperk,
      password: password,
      confPassword: confPassword,
      role: role,
      status: status,
      terminal_id: terminalid,
      kode_kantor:kodekantor,
    },{
      headers: {
        Authorization: `Bearer ${token}`
    }
    }).then((response) => {
      Swal.fire(
          'Berhasil!',
          response.data.message,
          'success'
      )
      handleCloseModal();
  })
}
}else{
  if(kodeuseribs == ''){
    Swal.fire(
      'Gagal!',
      'Id user IBS wajib di isi!',
      'error'
  )
  }else if (nameedit == ''){
    Swal.fire(
      'Gagal!',
      'Nama user wajib di isi!',
      'error'
  )
  }else if (email == ''){
    Swal.fire(
      'Gagal!',
      'Email user wajib di isi!',
      'error'
  )
  }else if (password != confPassword){
    Swal.fire(
      'Gagal!',
      'Password dan konfirmasi password tidak sama!',
      'error'
  )
  }else if (role == ''){
    Swal.fire(
      'Gagal!',
      'Role user wajib di isi!',
      'error'
  )
  }else if (status == ''){
    Swal.fire(
      'Gagal!',
      'Status user wajib di isi!',
      'error'
  )
  }else if (terminalid == ''){
    Swal.fire(
      'Gagal!',
      'Terminal ID wajib di isi!',
      'error'
  )
  }else if (kodekantor == ''){
    Swal.fire(
      'Gagal!',
      'Kode Kantor wajib di isi!',
      'error'
  )
  }else{
  await axiosJWT.patch(`${env.API_URL}/users/${idedit}`, {
      kode_user_ibs:kodeuseribs,
      name: nameedit,
      email: email,
      perk_kas: perkkas,
      nama_perk_kas: namaperk,
      password: password,
      confPassword: confPassword,
      role: role,
      status: status,
      terminal_id: terminalid,
      kode_kantor:kodekantor,
  },{
    headers: {
      Authorization: `Bearer ${token}`
  }
  }).then((response) => {
    Swal.fire(
        'Berhasil!',
        response.data.message,
        'success'
    )
    handleCloseModal();
})
  }
}
getUsers()
};

  const deleteUser = async (idedit) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
        if (result.isConfirmed) {
            await axios.delete(`${env.API_URL}/users/${idedit}`,{
              headers: {
                Authorization: `Bearer ${token}`
            }
            }).then((response) => {
                Swal.fire(
                    'Berhasil dihapus!',
                    response.data.message,
                    'success'
                )
            })
           
        }
        getUsers();
    })
}

const handleBlurKodePerk = async () => {
  try {
      const response = await axiosJWT.get(`${env.API_URL}/namaperk/${perkkas}`,{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setNamaPerk(response.data[0].NAMA_PERK);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }

};


return (
    <div className="p-2 mt-6 mb-2 mr-1" style={{backgroundColor:'#fff', borderRadius: '5px'}}>
      <h2 className="subtitle"><b>List Users</b></h2>
      <div className="box">
      <button  onClick={() => {handleOpenModal(); resetForm()}} className="button is-small is-info">
          Add New
        </button>
     
            <DataTable
                        columns={columns}
                        data={filteredUser}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight='450px'
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        // actions={<button onClick={//handleShow} className='btn btn-sm btn-info'>Add</button>}
                 
                        subHeader
                        subHeaderComponent={
                            <input
                                type='text'
                                placeholder='Search here'
                                className='w-25 form-control'
                                value={search}  
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                    />


<div className={`modal ${isModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Form User</b></p>
              <button
                onClick={handleCloseModal}
                className="delete"
                aria-label="close"
              />
            </header>
        <section className="modal-card-body">
        {/* <form > */}
        
                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Id User IBS</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                          type="text"
                          className="input is-small"
                          value={kodeuseribs}
                          onChange={(e) => setKodeUserIbs(e.target.value)}
                          placeholder="Id User IBS"
                        />
                        </p>
                      </div>
                      </div>
                  </div>


                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Name</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                          type="hidden"
                          className="input is-small"
                          value={idedit}
                          onChange={(e) => setIdEdit(e.target.value)}
                          placeholder="Name"
                        />
                        <input
                          type="text"
                          className="input is-small"
                          value={nameedit}
                          onChange={(e) => setNameEdit(e.target.value)}
                          placeholder="Name"
                        />
                        </p>
                      </div>
                      </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Email</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                          type="email"
                          className="input is-small"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                        />
                        </p>
                      </div>
                      </div>
                  </div>


                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Perk Kas</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                          type="text"
                          className="input is-small"
                          value={perkkas}
                          onChange={(e) => setPerkKas(e.target.value)}
                          onBlur={handleBlurKodePerk}
                          placeholder="Perkiraan Kas"
                        />
                        </p>
                      </div>
                      </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Nama Perk Kas</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                          type="text"
                          className="input is-small"
                          value={namaperk}
                          onChange={(e) => setNamaPerk(e.target.value)}
                          placeholder="Perkiraan Kas"
                          readOnly
                        />
                        </p>
                      </div>
                      </div>
                  </div>


                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Password</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                          type="password"
                          className="input is-small"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                        />
                        </p>
                      </div>
                      </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Conf Password</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                          type="password"
                          className="input is-small"
                          value={confPassword}
                          onChange={(e) => setConfPassword(e.target.value)}
                          placeholder="Konfirmasi Password"
                        />
                        </p>
                      </div>
                      </div>
                  </div>

                  
                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Role</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                        >
                          <option value="">-Pilih-</option>
                          <option value="admin">Admin</option>
                          <option value="petugas">Petugas</option>
                          <option value="user">User</option>
                        </select>
                        </div>
                        </p>
                      </div>
                      </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Status</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p clclassNameass="control">
                        <div className="select is-fullwidth is-small">
                        <select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          >
                          <option value="">-Pilih-</option>
                          <option value="0">Aktif</option>
                          <option value="1">Blokir</option>
                        </select>
                        </div>
                        </p>
                      </div>
                      </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Terminal ID</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                          type="text"
                          className="input is-small"
                          value={terminalid}
                          onChange={(e) => setTerminalID(e.target.value)}
                          placeholder="Terminal ID"
                        />
                        </p>
                      </div>
                      </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Kode Kantor</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                          type="text"
                          className="input is-small"
                          value={kodekantor}
                          onChange={(e) => setKodeKantor(e.target.value)}
                          placeholder="Kode Kantor"
                        />
                        </p>
                      </div>
                      </div>
                  </div>
              

            {/* </form> */}
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success is-small" onClick={(e) => {saveUser(e);}} >Save</button>
              <button onClick={handleCloseModal} className="button is-small">
                Cancel
              </button>
            </footer>
        </div>
      </div>
      </div>

    </div>
  );
};

export default Userlist;
