import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import "bulma/css/bulma.css";
import 'bulma/css/bulma.min.css';
import jwt_decode from 'jwt-decode';
import env from "react-dotenv";


const RekeningList = () => {
  const { user } = useSelector((state) => state.auth);
  const [nama, setNama] = useState('');
  const [alamat, setAlamat] = useState('');
  const [produk, setProduk] = useState('');
  const [saldo, setSaldo] = useState('');
  const navigate = useNavigate(); 

  const [name, setName] = useState('')
  const [token, setToken] = useState('')
  const [expire, setExpire] = useState('')
  const axiosJWT = axios.create()
  const [msg, setMsg] = useState("");

  const norek = user && user.norek;


  useEffect(() => {
    getRekeningByNorek(norek);
  }, [norek]);


axiosJWT.interceptors.request.use(async (config) => {
  const currentDate = new Date()
  if (expire * 1000 < currentDate.getTime()) {
      const response = await axios.get(`${env.API_URL}/token`)
      config.headers.Authorization = `Bearer ${response.data.accessToken}`
      setToken(response.data.accessToken)
      const decoded = jwt_decode(response.data.accessToken)
      setName(decoded.name)
      setExpire(decoded.exp)
  }
  return config
}, (error) => Promise.reject(error))


  const getRekeningByNorek = async (norek) => {
    try {
      const response = await axiosJWT.get(`${env.API_URL}/getsaldo/${norek}`,{
        headers: {
            Authorization: `Bearer ${token}`
        }
      });
      setNama(response.data[0].NAMA_NASABAH);
      setAlamat(response.data[0].ALAMAT);
      setProduk(response.data[0].DESKRIPSI_PRODUK);
      setSaldo(new Intl.NumberFormat('id-ID').format(response.data[0].SALDO_AKHIR));
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
<div className="p-5 mt-2 mb-2 mr-3" style={{backgroundColor:'#fff', borderRadius: '5px'}}>

<h3 className="subtitle"><b>Informasi Rekening</b></h3>
<div class="box">
<div className="field is-horizontal mb-0">
    <label className="label is-small column is-1 pt-1">No Rekening</label>
    <input className="input is-small is-static"  type="text" value={user && user.norek} placeholder="Nomor Rekening" />
</div>

<div className="field is-horizontal mt-0 mb-0">
    <label className="label is-small column is-1 pt-1">Nama</label>
    <input className="input is-small is-static"  type="text" value={nama} placeholder="Nama Nasabah" />
</div>

<div className="field is-horizontal mt-0 mb-0">
    <label className="label is-small column is-1 pt-1">Alamat</label>
    <input className="input is-small is-static"  type="text" value={alamat} placeholder="Alamat Nasabah" />
</div>

<div className="field is-horizontal mt-0 mb-0">
    <label className="label is-small column is-1 pt-1">Produk</label>
    <input className="input is-small is-static"  type="text" value={produk} placeholder="Produk Tabungan" />
</div>

<div className="field is-horizontal mt-0 mb-0">
    <label className="label is-small column is-1 pt-1">Saldo</label>
    <input className="input is-small is-static"  type="text" value={saldo} placeholder="Saldo" />
</div>
</div>

</div>
  );
};

export default RekeningList;
