import React, { useState } from "react";
import { IoSearch } from "react-icons/io5";
import "bulma/css/bulma.css";
import 'bulma/css/bulma.min.css';
import axios from "axios";
import jwt_decode from 'jwt-decode';
import Iframe from 'react-iframe';
import { useLocalStorage } from './Utils';
import Swal from 'sweetalert2';
import env from "react-dotenv";

const CekMutasiList = () => {

  const [name, setName] = useLocalStorage('name', '')
  const [token, setToken] = useState('')
  const [expire, setExpire] = useState('')
  const axiosJWT = axios.create()

  const [uuid, setUuid] = useState('');
  const [urlbyid, setUrlById] = useState('')
  const [msg, setMsg] = useState("");

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${env.API_URL}/token`)
        config.headers.Authorization = `Bearer ${response.data.accessToken}`
        setToken(response.data.accessToken)
        const decoded = jwt_decode(response.data.accessToken)
        setName(decoded.name)
        setExpire(decoded.exp)
    }
    return config
}, (error) => Promise.reject(error))


  const getMutasi = async (e) => {
    e.preventDefault();

    if(uuid == ''){
      Swal.fire(
        'Gagal!',
        'Kode verifikasi file mutasi belum di isi',
        'error'
      )
      setUrlById('')
    }else{
      try {
        const response = await axiosJWT.get(`${env.API_URL}/logmutasi/${uuid}`,{
          headers: {
            Authorization: `Bearer ${token}`
        }
        });
        console.log(response.data)
        if(!response.data){
          Swal.fire(
            'Gagal!',
            'File mutasi tidak ditemukan!',
            'error'
          )
          setUrlById('')
        }else{
          var uuid_log = response.data.uuid;
          var norek = response.data.no_rekening;
          var tgl_mulai = response.data.tgl_mulai;
          var tgl_selesai = response.data.tgl_selesai;
          setUrlById('/printmutasi/'+norek+'/'+tgl_mulai+'/'+tgl_selesai+'/'+uuid_log)
        }
  
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    }
  };
 
  return (
<div className="p-2 mt-6 mb-2 mr-1" style={{backgroundColor:'#fff', borderRadius: '5px'}}>

<h2 className="subtitle"><b>Chek File Mutasi Rekening</b></h2>
<div className="box">
<div className="field is-horizontal mb-0">
    <label className="label is-small column is-1 pt-1">Kode Verifikasi</label>
    <input className="input is-small column is-3 mr-2" type="text" value={uuid} onChange={(e) => setUuid(e.target.value)}   />
    <button className="button is-small is-primary" onClick={(e) => getMutasi(e)} title="Cari File Mutasi"><IoSearch size="20"/></button>
</div>

<div className="mt-5">
<Iframe src={urlbyid}
        width="100%"
        height="700px"
        id=""
        className=""
        display="block"
        position="relative"/>
</div>
</div>

</div>
  );
};

export default CekMutasiList;
