import React, { useState, useEffect } from "react";
import { Parallax } from 'react-parallax';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoArrowUp, IoBusiness, IoCall, IoGlobe, IoMail,IoLogoInstagram, IoLogoFacebook, IoLogoYoutube, IoLogoLinkedin, IoLogoGoogle, IoLogInSharp, IoRefresh } from "react-icons/io5";
import { LoginUser, reset } from "../features/authSlice";
import axios from "axios";
import logo from "../logo.png";
import { Link, Element, Events, animateScroll as scroll } from 'react-scroll';
import 'bulma/css/bulma.css';
import env from "react-dotenv";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [captchaImage, setCaptchaImage] = useState('');
  const [captchaText, setCaptchaText] = useState('');

  const [captchaCode, setCaptchaCode] = useState('');
  
  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [isActive, setIsActive] = useState(false);

  const notify = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER
    });
  }


  useEffect(() => {
    if (user || isSuccess) {
      sessionStorage.setItem('islogin', true);
      navigate("/dashboard");
    } else if (isError) {
      notify(message);
    }
    dispatch(reset());
  }, [user, isSuccess, isError, isLoading, dispatch, navigate]);

  useEffect(() => {
    fetchCaptcha();  
  }, []);


  const reloadCaptcha = async () => {
    fetchCaptcha();
  };


  const fetchCaptcha = async () => {
    try {
      const response = await axios.get(`${env.API_URL}/captcha`, { responseType: 'json' });
      const { captcha, image } = response.data;

      setCaptchaText(captcha);

      setCaptchaImage(image);
    } catch (error) {
      console.error(error);
    }
  };



  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const toggleDropdown = () => {
    setIsActive(!isActive);
  };
  

  const handleSetActive = (to) => {
    setActiveSection(to);
  };

  const handleScrollToTop = () => {
    scroll.scrollToTop();
  };

  const menuItems = [
    { id: 'beranda', text: 'Beranda' },
    { id: 'informasi', text: 'Informasi' },
    { id: 'contact', text: 'Hubungi Kami' }
  ];

  Events.scrollEvent.register('end', () => {
    if (window.pageYOffset === 0) {
      setActiveSection('');
    }
  });

  const backToTopStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#f14668',
    color: '#fff',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '10px'
  };
  

  const Auth = (e) => {
    e.preventDefault();
    dispatch(LoginUser({ email, password, captchaCode, captchaText}));
  };



const VerticalLine = () => {
  return (
    <div
      style={{
        borderLeft: '1px solid #696969',
        height: '100%',
        marginRight: '10px',
      }}
    ></div>
  );
};

const HorizontalLine = () => {
  return (
    <div
      style={{
        borderTop: '1px solid #cfcfcf',
        width: '100%',
        marginTop: '10px',
      }}
    ></div>
  );
};


return (
    <div>
        <ToastContainer />
  <nav className="navbar is-fixed-top has-shadow is-transparent">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
        <img src={logo} width="150" alt="logo" />
        </a>

        <button
          className={`navbar-burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded={isActive ? "true" : "false"}
          onClick={toggleDropdown}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
        <div className="navbar-end">
          <div className="navbar-item">
                    {menuItems.map((item) => (
                        <Link
                          className="navbar-item"
                          to={item.id}
                          smooth={true}
                          duration={700}
                          activeClass="active"
                          spy={true}
                          onSetActive={handleSetActive}>
                          {item.text}
                        </Link>
                    ))}
          </div>
        </div>
      </div>
    </nav>


    <Parallax bgImage="/images/kantor.png" strength={600}>
      <Element name="beranda" className="section hero is-fullwidth" style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", paddingBottom:'100px', paddingTop:'100px'}}>
        <div className="columns is-centered is-vcentered is-fullheight pt-6">
          <div className="column is-half">
            <h3 className="is-3 has-text-centered" style={{fontSize:'30px', color:'#fff'}}>SISTEM LAYANAN<br /> <b>BALANCE INQUIRY</b><br /> PT BPR BANK BOYOLALI (PERSERODA)</h3>
            <div className="has-text-centered p-5">
              <div className="button is-danger" title="Login Aplikasi" onClick={handleOpenModal} ><IoLogInSharp size={30}/></div>
            </div>
          </div>
        </div>
      </Element>
    </Parallax>

      <Element name="informasi" className="section hero is-fullwidth has-shadow" style={{backgroundColor:'#f3f3f3'}}>
          <div>
          <div className="columns p-6">
            <div className="column is-4">
              <h3 style={{ fontSize: '20px', textAlign: 'right' }}>
                INFORMASI<br />
                SISTEM LAYANAN<br />
                <b>BALANCE INQUIRY</b><br />
                PT BPR BANK BOYOLALI (PERSERODA)
              </h3>
            </div>
            <div className="column is-8" style={{display: 'flex', alignItems: 'center' }}>
              <VerticalLine />
              <p style={{width:'80%'}}>Untuk Informasi terkait <b>SISTEM LAYANAN BALANCE INQUIRY</b> PT BPR BANK BOYOLALI (PERSERODA), anda dapat langsung datang ke kantor kami terdekat di wilayah Kabupaten Boyolali
              atau menghubungi Customer Service kami di : <br />
              <h3 style={{fontsize:'30px'}}><b>Telp. (0276) 321393</b></h3></p>
            </div>
          </div>
          </div>        
      </Element>

      <Element name="contact" className="section hero is-fullwidth has-shadow" style={{backgroundColor:'#ebebeb'}}>
      <div>
        <div className="columns">
          <div className="column is-6">
          <h2 style={{ fontSize: '20px'}}><b>HUBUNGI KAMI</b></h2>
          <HorizontalLine />  
          <p>Silahkan hubungi atau kunjungi kami melalui kontak dibawah ini.<br />
          <IoBusiness />  Jl. Merbabu No 2B Boyolali Jawa Tengah 57311<br />
          <IoCall /> (0276) 321393 (Hunting)<br />
          <IoGlobe /> www.bankboyolali.com<br />
          <IoMail /> bankboyolali@yahoo.com</p>

          </div>
          <div className="column is-6">
          <h2 style={{ fontSize: '20px'}}><b>SOSIAL MEDIA</b></h2>
          <HorizontalLine />  
          <p>
          <IoLogoInstagram /> pt.bankboyolali<br />
          <IoLogoFacebook /> PTBPRBankBoyolali<br />
          <IoLogoYoutube /> PT BPR BANK BOYOLALI<br />
          <IoLogoLinkedin /> PT BPR Bank Boyolali<br />
          <IoLogoGoogle /> Bank Boyolali</p>
         </div>
        </div> 
        <HorizontalLine />
        <div className="columns">
          <div className="column">
          <p><b>Terdaftar dan diawasi oleh :</b><br /> <br />
          <img src='/images/ojk.png' width="80" alt="logo" /> 
          <img style={{marginLeft:'8px'}} src='/images/LPS.png' width="70" alt="logo" />
          <img style={{marginLeft:'8px'}} src='/images/BPR.png' width="25" alt="logo" /></p>
          </div> 
        </div> 

      </div> 
      </Element>


      <div lassName="section hero is-fullwidth" style={{height:'50px',backgroundColor:'#2D2D2D', padding: '10px', textAlign: 'center'}}>
        <h3 style={{color: '#fff'}}>© Copyright 2023 <b>Tim IT Bank Boyolali</b></h3>
      </div> 

      <div style={backToTopStyle} onClick={handleScrollToTop} title='Back To Top'>
        <IoArrowUp />
      </div>

      <div className={`modal ${isModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
  
        <div className="modal-content" style={{width: '400px'}}>
            <div className="box">

            <div className="is-fullwidth mb-3">
              <button onClick={handleCloseModal} className="delete is-pulled-right" aria-label="close" />
              <p className="modal-card-title">Sign In</p>
            </div>
            <HorizontalLine />
              <div className="field is-horizontal mt-3">
                <div className="field-label is-small">
                  <label className="label" style={{textAlign: 'left'}}>Email</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                    <input
                      type="text"
                      className="input is-small"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                    />
                    </p>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-small">
                  <label className="label" style={{textAlign: 'left'}}>Password</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                    <input
                      type="password"
                      className="input is-small"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="******"
                    />
                    </p>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-label is-small">
                  <label className="label" style={{textAlign: 'left'}}>Captcha</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control">
                    {captchaImage && <img src={`data:image/png;base64,${captchaImage}`} alt="Captcha Image" />} 
                    <div className="button is-normal is-default ml-2" title="Refresh Captcha" onClick={reloadCaptcha}><IoRefresh /></div>
                    <input
                      type="text"
                      name="captchaInput"
                      className="input is-small"
                      value={captchaCode}
                      onChange={(e) => setCaptchaCode(e.target.value)}
                      placeholder="Kode Keamanan"
                    />
                    <input
                      type="hidden"
                      className="input is-small"
                      value={captchaText}
                      onChange={(e) => setCaptchaText(e.target.value)}
                    />
                    </p>
                  </div>
                </div>
              </div>
                
              <div className="is-horizontal">
                <button className="button is-danger is-fullwidth" onClick={(e) => { Auth(e); {isSuccess && handleCloseModal()} ;}}>
                  {isLoading ? "Loading..." : "Login"}
                </button>
              </div>
                
              </div>
        </div>
      </div>



    </div>
      
  );
};

export default Login;
