import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IoPrint } from "react-icons/io5";
import "bulma/css/bulma.css";
import 'bulma/css/bulma.min.css';
import Iframe from 'react-iframe';
import Swal from 'sweetalert2';
import env from "react-dotenv";


const MutasiList = () => {
  const { user } = useSelector((state) => state.auth);

  const [tglmulai, setTglMulai] = useState('');
  const [tglselesai, setTglSelesai] = useState('');
  const [urlbyid, setUrlById] = useState('')

  const norek = user && user.norek;
  const uuid = user && user.uuid;

  const formattanggal = (e) => {
    var date = new Date(e); 
    return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)).toLocaleString('en-ID', { hour12:false } );
  }

  const getMutasi = async (e) => {
    e.preventDefault();
    const startDateObj = new Date(tglmulai);
    const endDateObj = new Date(tglselesai);

    const timeDiff = Math.abs(endDateObj.getTime() - startDateObj.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));


    if(tglmulai == ''){
      Swal.fire(
        'Gagal!',
        'Tanggal mulai belum di isi',
        'error'
      )
      setUrlById('')
    }else if(tglselesai == ''){
      Swal.fire(
        'Gagal!',
        'Tanggal selesai belum di isi',
        'error'
      )
      setUrlById('')
    }else if(tglmulai > tglselesai){
      Swal.fire(
        'Gagal!',
        'Tanggal mulai tidak boleh lebih besar dari tanggal selesai',
        'error'
      )
      setUrlById('')
    }else if(diffDays > 30){
      Swal.fire(
        'Gagal!',
        'Periode tanggal tidak boleh melebihi 30 hari',
        'error'
      )
      setUrlById('')
    }else{
    var tglmulaif = formattanggal(tglmulai);
    var tglselesaif = formattanggal(tglselesai);
    
    setUrlById('/printmutasi/'+norek+'/'+tglmulaif+'/'+tglselesaif+'/'+uuid)
    }
  
  };
 
  return (
<div className="p-2 mt-6 mb-2 mr-1" style={{borderRadius: '5px'}}>

<h3 className="subtitle"><b>Mutasi Rekening</b></h3>
<div className="box">
<div className="field is-horizontal mb-0">
    <label className="label is-small column is-1 pt-1">Periode</label>
    <input className="input is-small column is-3 mr-2" type="date" value={tglmulai} onChange={(e) => setTglMulai(e.target.value)}   />
    <input className="input is-small column is-3 mr-2"  type="date" value={tglselesai} onChange={(e) => setTglSelesai(e.target.value)} />
    <button className="button is-small is-primary" onClick={(e) => getMutasi(e)} title="Cetak Mutasi"><IoPrint size="20"/></button>
</div>

<div className="mt-5">
<Iframe src={urlbyid}
        width="100%"
        height="300px"
        id=""
        className=""
        display="block"
        position="relative"/>
</div>
</div>

</div>
  );
};

export default MutasiList;
