import React from "react";

const Footer = () => {
  return (
    <footer style={footerStyle}>
      {/* Tambahkan konten footer di sini */}
      <p style={{ fontSize: "14px", color: "#FF406F" }}><b>&copy; 2023 Tim IT Bank Boyolali. All Rights Reserved.</b></p>
    </footer>
  );
};

const footerStyle = {
  backgroundColor: "#fff",
  padding: "15px",
  textAlign: "right",
  position: "fixed",
  bottom: "0",
  left: "0",
  width: "100%",
};

export default Footer;
