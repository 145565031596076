// import React from "react";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../logo.png";
import { useDispatch, useSelector } from "react-redux";
import { IoKeyOutline, IoLogOut, IoPersonCircle} from "react-icons/io5";
import { LogOut, reset } from "../features/authSlice";
import Swal from 'sweetalert2';
import axios from "axios";
import env from "react-dotenv";

const Navbar = ({ toggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [msg, setMsg] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confpassword, setConfPassword] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

    const [isActive, setIsActive] = useState(false);

  const toggleDropdown = () => {
    setIsActive(!isActive);
  };
  

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const resetForm = () => {
    setOldPassword("")
    setNewPassword("")
    setConfPassword("")
  }


  const logout = () => {
    Swal.fire({
      title: 'Logout',
      text: "Are you sure you want to log out?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
  }).then(async(result) => {
      if (result.isConfirmed) {
          dispatch(LogOut());
          dispatch(reset());
          navigate('/')
          localStorage.clear()
          sessionStorage.clear()
      }
  })
  };

  const password_validate = (password) => {
    var re = {
        capital: /(?=.*[A-Z])/,
        length: /(?=.{6,40}$)/,
        specialChar: /[ -\/:-@\[-\`{-~]/,
        digit: /(?=.*[0-9])/,
    };
    return (
        re.capital.test(password) &&
        re.length.test(password) &&
        re.specialChar.test(password) &&
        re.digit.test(password)
    );
 };

  const savePassword = async (e) => {
      e.preventDefault();

      if (oldpassword == '' || newpassword == '' || confpassword == ''){
        Swal.fire(
          'Gagal!',
          'Tidak boleh kosong',
          'error'
        )
      }else if(!password_validate(newpassword)){
        Swal.fire(
          'Gagal!',
          'Panjang password minimal 6 karakter dan harus mengandung huruf kapital, angka dan spesial karakter.',
          'error'
        )
      }else if(newpassword != confpassword){
        Swal.fire(
          'Gagal!',
          'Password baru tidak sama dengan konfirmasi password',
          'error'
        )
      }else{
      try {
        await axios.patch(`${env.API_URL}/changepassword/${user && user.uuid}`
        , {
          oldpassword: oldpassword,
          newpassword: newpassword,
        }
        ).then((response) => {
          Swal.fire(
              'Berhasil!',
              response.data.msg,
              'success'
          )
      })
      handleCloseModal()
      } catch (error) {
        if (error.response) {
          Swal.fire(
            'Gagal!',
            error.response.data.msg,
            'error'
          )
        }
      }
    }
    
  };


  return (  
    <div>
      <nav
        className="navbar is-fixed-top has-shadow"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <NavLink to="/dashboard" className="navbar-item">
            <img src={logo} width="150" alt="logo" />
          </NavLink>
        <div
          className={`navbar-burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded={isActive ? "true" : "false"}
          onClick={toggleDropdown}
        >
          <div style={{marginLeft:'15px', marginTop:'15px'}}>
                 <IoPersonCircle size={24}/>
         </div>
          {/* <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span> */}
        </div>

        </div>

        <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable pr-6">
                  <a className="navbar-link ml-2" href="#">
                      {user && user.name}
                    </a>
                    <div className="navbar-dropdown pr-4">
                      <a className="navbar-item" onClick={() => {handleOpenModal();resetForm()}} href="#"><IoKeyOutline />  Change Password</a>
                      <a className="navbar-item" onClick={() => {logout()}} href="#"><IoLogOut />  Logout</a>
                    </div>
            </div>
          </div>    
        </div>

        <div className={`modal ${isModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
        <header className="modal-card-head">
              <p className="modal-card-title" style={{fontSize: '20px'}}><b>Change Password</b></p>
              <button
                onClick={handleCloseModal}
                className="delete"
                aria-label="close"
              />
            </header>
        <section className="modal-card-body">
        {/* <form> */}

              <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Password Lama</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                            type="password"
                            className="input is-small"
                            value={oldpassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            placeholder="Password Lama"
                          />
                        </p>
                      </div>
                      </div>
                  </div>


                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Password Baru</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                            type="password"
                            className="input is-small"
                            value={newpassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Password Baru"
                          />
                        </p>
                      </div>
                      </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-small">
                      <label className="label">Conf Password</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                        <input
                            type="password"
                            className="input is-small"
                            value={confpassword}
                            onChange={(e) => setConfPassword(e.target.value)}
                            placeholder="Konfirmasi Password"
                          />
                        </p>
                      </div>
                      </div>
                  </div>

            {/* </form> */}
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success is-small" onClick={(e) => {savePassword(e);}} >Save</button>
              <button onClick={handleCloseModal} className="button is-small">
                Cancel
              </button>
            </footer>
        </div>
      </div>

        
      </nav>

    </div>
  );
};

export default Navbar;
