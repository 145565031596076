import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { IoGridSharp, IoSettings, IoEyeSharp, IoSwapHorizontal, IoSearch } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText
} from "@trendmicro/react-sidenav";

const SideNavBar = ({ sideNavExpanded, setSideNavExpanded }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  
  const handleSelect = (selected) => {

    // Lakukan sesuatu berdasarkan item yang dipilih
    if (selected === 'dashboard') {
      navigate('/dashboard')
    } else if (selected === 'rekening') {
      navigate('/rekening')
    } else if (selected === 'mutasi') {
      navigate('/mutasi')
    } else if (selected === 'cekmutasi') {
      navigate('/cekmutasi')
    } else if (selected === 'setting/users') {
      navigate('/users')
    }
  };

  const HorizontalLine = () => {
    return (
      <div
        style={{
          borderTop: '1px solid #cfcfcf',
          width: '100%',
          marginTop: '10px',
        }}
      ></div>
    );
  };
  
  
    return (
      <div>
      <SideNav 
      onSelect={handleSelect} 
      onToggle={() => {
        setSideNavExpanded(!sideNavExpanded);
      }}
      expanded={sideNavExpanded}
      >
      <SideNav.Toggle  />
      <SideNav.Nav>

       
      <NavItem eventKey="dashboard" active={location.pathname === '/dashboard'}>
          <NavIcon style={{paddingTop: '5px'}}>          
            <IoGridSharp  size={20}/>
          </NavIcon>
          <NavText style={{ fontSize: '16px' }}>Dashboard</NavText>
        </NavItem>

        <NavItem eventKey="rekening" active={location.pathname === '/rekening'}>
          <NavIcon style={{paddingTop: '5px'}}>          
            <IoEyeSharp  size={20}/>
          </NavIcon>
          <NavText style={{ fontSize: '16px' }}>Info Rekening</NavText>
        </NavItem>

        
        <NavItem eventKey="mutasi" active={location.pathname === '/mutasi'}>
          <NavIcon style={{paddingTop: '5px'}}>          
            <IoSwapHorizontal  size={20}/>
          </NavIcon>
          <NavText style={{ fontSize: '16px' }}>Mutasi Rekening</NavText>
        </NavItem>

        <HorizontalLine />
        {user && user.role != "user" && (
        <NavItem eventKey="cekmutasi" active={location.pathname === '/cekmutasi'}>
          <NavIcon style={{paddingTop: '5px'}}>          
            <IoSearch  size={20}/>
          </NavIcon>
          <NavText style={{ fontSize: '16px' }}>Cek File Mutasi</NavText>
        </NavItem>
          )}


          <NavItem eventKey="setting">
          <NavIcon style={{paddingTop: '5px'}}>
            <IoSettings size={20}/>
          </NavIcon>
          <NavText>Setting</NavText>
          <NavItem eventKey="setting/users" active={location.pathname === '/users'}>
            <NavText>Users</NavText>
          </NavItem>
          <NavItem eventKey="setting/changepass">
            <NavText>Chang Password</NavText>
          </NavItem>
          <NavItem eventKey="setting/barchart">
            <NavText>Logout</NavText>
          </NavItem>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
    </div>
    );
};

export default SideNavBar;
