import React, { useEffect, useState } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import {useParams } from 'react-router-dom'
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
    PDFViewer } from "@react-pdf/renderer";
import logo from "../logo.png";


const PrintMutasi = ({}) => {
    const { norek, tgl_awal, tgl_akhir, uuid } = useParams()
    const [mutasi, setMutasi] = useState([]);
    const [saldoawal, setSaldoAwal] = useState('');
    const [saldoakhir, setSaldoAkhir] = useState('');
    const [name, setName] = useState('')
    const [token, setToken] = useState('')
    const [expire, setExpire] = useState('')
    const axiosJWT = axios.create()

    const [norekdb, setNorek] = useState('');
    const [nama, setNama] = useState('');
    const [alamat, setAlamat] = useState('');
    const [produk, setProduk] = useState('');
    const [msg, setMsg] = useState("");
    const [qrCodeUrl, setQRCodeUrl] = useState("");

    
    let debet = 0;
    let kredit = 0;
    let url_patch = 'https://api-bbs.xyz/bi';
    

    useEffect(() => {
        getMutasi()
        getRekeningByNorek()
        insertLog()
    }, [])


    axiosJWT.interceptors.request.use(async (config) => {
      const currentDate = new Date()
      if (expire * 1000 < currentDate.getTime()) {
          const response = await axios.get(`${url_patch}/token`)
          config.headers.Authorization = `Bearer ${response.data.accessToken}`
          setToken(response.data.accessToken)
          const decoded = jwt_decode(response.data.accessToken)
          setName(decoded.name)
          setExpire(decoded.exp)
      }
      return config
  }, (error) => Promise.reject(error))


const getMutasi = async () => {
    const response = await axiosJWT.get(`${url_patch}/getmutasi/${norek}/${tgl_awal}/${tgl_akhir}`,{
          headers: {
              Authorization: `Bearer ${token}`
          }
        });
        setMutasi(response.data);
        setSaldoAwal(response.data[0].saldo_awal);
        var datalenght = response.data.length - 1;
        setSaldoAkhir(response.data[datalenght].saldo_akhir);
       
}

const getRekeningByNorek = async () => {
    try {
      const response = await axiosJWT.get(`${url_patch}/getsaldo/${norek}`,{
        headers: {
            Authorization: `Bearer ${token}`
        }
      });
      setNorek(response.data[0].NO_REKENING);
      setNama(response.data[0].NAMA_NASABAH);
      setAlamat(response.data[0].ALAMAT);
      setProduk(response.data[0].DESKRIPSI_PRODUK);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
};


const insertLog = async () => {
    try {
      const response = await axiosJWT.post(`${url_patch}/logmutasi`, {
        user_uuid: uuid,
        no_rekening: norek,
        tgl_mulai: tgl_awal,
        tgl_selesai: tgl_akhir,
      },{
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setQRCodeUrl(response.data.qrCodeUrl);
      // console.log(response)
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
     
}


// Komponen Header
const PageHeader = () => {
  const styles = StyleSheet.create({
    header: {
      marginBottom: 10,
      textAlign: "center",
      fontSize: 12,
    },
    hr: {
      borderBottomColor: "black",
      borderBottomWidth: 1,
      marginVertical: 10,
    },
    image: {
      width: 200,
      height: 40,
    },
    Row: { flexDirection: 'row' },

  });

  return (
    <View fixed style={styles.header}>
      <View style={styles.Row}>
      <Image src={logo} style={styles.image} />
      <Text style={{textAlign:'left', fontSize:'11', marginLeft:'5', paddingTop:'5', paddingLeft: '5', borderLeftWidth:'1', borderLeftColor: "grey"}}>PT BPR BANK BOYOLALI (PERSERODA){'\n'}
      JL. Merbabu No 2B Boyolali, Jawa Tengah 57311{'\n'}
      Telp./Fax. (0276) 321393 (Hunting)
      </Text>
      </View>
      <View style={styles.hr} />
    </View>
    
  );
};


// Komponen Footer
const PageFooter = () => {
  const styles = StyleSheet.create({
    footer: {
      position: "absolute",
      bottom: 20,
      left: 25,
      right: 25,
      textAlign: "center",
      fontSize: 8,
    },
    Row: { flexDirection: 'row' },
    hr: {
      borderBottomColor: "#e2e2e2",
      borderBottomWidth: 1,
      marginVertical: 10,
    }
  
  });

  return (
    <View fixed style={styles.footer}>
      <View style={styles.hr} />
      <View style={styles.Row}>
      <Text>Dicetak oleh : {name} | Pada {[TampilTgl(new Date()), ' ', tampilWaktu(new Date())]}</Text>
      <Text style={{marginLeft:'auto'}} render={({ pageNumber, totalPages }) => (
        `Halaman ${pageNumber} / ${totalPages}`
      )} fixed />
      </View>
    </View>
  );
};


const generateTableData = () => {
  
  return mutasi.map((item, index) => {
    const penarikan = Number(item.penarikan);
    const setoran = Number(item.setoran);

    if(!isNaN(penarikan)){
      debet += penarikan;
    }

    if(!isNaN(setoran)){
      kredit += setoran;
    }

    return (
    <View style={styles.tableRow} key={index}>
       <View style={[styles.tableCell, {width:'20px'}]}>
        <Text>{1+index}</Text>
      </View>
      <View style={[styles.tableCell, {width:'50px'}]}>
        <Text>{dateFormattDDMMYYY(item.tgl_trans)}</Text>
      </View>
      <View style={[styles.tableCell, {width:'50px'}]}>
        <Text>{item.kuitansi}</Text>
      </View>
      <View style={[styles.tableCell, {width:'150px'}]}>
        <Text>{item.KETERANGAN}</Text>
      </View>
      <View style={[styles.tableCell, {width:'30px'}]}>
        <Text style={{ textAlign: "center" }}>{item.MY_KODE_TRANS}</Text>
      </View>
      <View style={[styles.tableCell, {width:'40px'}]}>
        <Text style={{ textAlign: "center" }}>{item.SANDI_TRANS}</Text>
      </View>
      <View style={[styles.tableCell, {width:'70px'}]}>
        <Text style={{ textAlign: "right" }}>{new Intl.NumberFormat('id-ID').format(item.penarikan)}</Text>
      </View>
      <View style={[styles.tableCell, {width:'70px'}]}>
        <Text style={{ textAlign: "right" }}>{new Intl.NumberFormat('id-ID').format(item.setoran)}</Text>
      </View>
      <View style={[styles.tableCell, {width:'70px', borderRightWidth: 0}]}>
        <Text style={{ textAlign: "right" }}>{new Intl.NumberFormat('id-ID').format(item.saldo_akhir)}</Text>
      </View>
    </View>
    );
     
    });
    
};



var TampilTgl = function (yourDate) {
var date = new Date(yourDate);
var tahun = date.getFullYear();
var bulan = date.getMonth();
var tanggal = date.getDate();
var hari = date.getDay();
var jam = date.getHours();
var menit = date.getMinutes();
var detik = date.getSeconds();
switch(hari) {
 case 0: hari = "Minggu"; break;
 case 1: hari = "Senin"; break;
 case 2: hari = "Selasa"; break;
 case 3: hari = "Rabu"; break;
 case 4: hari = "Kamis"; break;
 case 5: hari = "Jum'at"; break;
 case 6: hari = "Sabtu"; break;
}
switch(bulan) {
 case 0: bulan = "Januari"; break;
 case 1: bulan = "Februari"; break;
 case 2: bulan = "Maret"; break;
 case 3: bulan = "April"; break;
 case 4: bulan = "Mei"; break;
 case 5: bulan = "Juni"; break;
 case 6: bulan = "Juli"; break;
 case 7: bulan = "Agustus"; break;
 case 8: bulan = "September"; break;
 case 9: bulan = "Oktober"; break;
 case 10: bulan = "November"; break;
 case 11: bulan = "Desember"; break;
}
// var tampilTanggalHari = "Tanggal: " + hari + ", " + tanggal + " " + bulan + " " + tahun;
var TampilTgl = tanggal + " " + bulan + " " + tahun;
// var tampilWaktu = jam + ":" + menit + ":" + detik;
// console.log(tampilTanggalHari);


return TampilTgl;
}

var tampilWaktu = function (yourDate) {
  var date = new Date(yourDate);
  var jam = date.getHours();
  var menit = date.getMinutes();
  var detik = date.getSeconds();
 
  var tampilWaktu = jam + ":" + menit + ":" + detik;
  
  
  return tampilWaktu;
  }

var dateFormattDDMMYYY = function (yourDate) {
  var date = new Date(yourDate);
  var dd = date.getDate();

  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  if (dd < 10) {
      dd = '0' + dd;
  }

  if (mm < 10) {
      mm = '0' + mm;
  }

  date = dd + '/' + mm + '/' + yyyy;
  return date;
}

    return (

      <PDFViewer style={styles.viewer}>
      <Document title='Mutasi Rekening Tabungan'>
       <Page size='A4' style={styles.page}>
       <PageHeader />
       <View style={styles.h3}><Text>LAPORAN REKENING KORAN TABUNGAN</Text></View>

       <View style={styles.Row}>
        <View style={{width:'60px'}}>
        <View><Text>No Rekening </Text></View>
        </View>
        <View style={styles.Cell}>
        <View><Text> :</Text></View>
        </View>
        <View style={{width:'300px'}}>
        <View><Text> {norekdb}</Text></View>
        </View>

        <View style={{width:'60px'}}>
        <View><Text>Periode </Text></View>
        </View>
        <View style={styles.Cell}>
        <View><Text> :</Text></View>
        </View>
        <View style={styles.Cell}>
        <View><Text> {TampilTgl(tgl_awal)}</Text></View>
        </View>
       </View>

       <View style={styles.Row}>
        <View style={{width:'60px'}}>
        <View><Text>Nama Nasabah </Text></View>
        </View>
        <View style={styles.Cell}>
        <View><Text> :</Text></View>
        </View>
        <View style={{width:'300px'}}>
        <View><Text> {nama}</Text></View>
        </View>

        <View style={{width:'60px'}}>
        <View><Text>Sampai Dengan </Text></View>
        </View>
        <View style={styles.Cell}>
        <View><Text> :</Text></View>
        </View>
        <View style={styles.Cell}>
        <View><Text> {TampilTgl(tgl_akhir)}</Text></View>
        </View>
       </View>

       <View style={styles.Row}>
        <View style={{width:'60px'}}>
        <View><Text>Alamat </Text></View>
        </View>
        <View style={styles.Cell}>
        <View><Text> :</Text></View>
        </View>
        <View style={{width:'300px'}}>
        <View><Text> {alamat}</Text></View>
        </View>

        <View style={{width:'60px'}}>
        <View><Text>Jenis Simpanan </Text></View>
        </View>
        <View style={styles.Cell}>
        <View><Text> :</Text></View>
        </View>
        <View style={styles.Cell}>
        <View><Text> {produk}</Text></View>
        </View>
       </View>
       <View style={{marginBottom : '10px'}}></View>

        <View style={[styles.table, { pageBreakInside : "auto" }]}>
          <View fixed style={styles.tableRow}>
          <View style={[styles.tableHeader, {width:'20px'}]}>
              <Text>No</Text>
            </View>
            <View style={[styles.tableHeader, {width:'50px'}]}>
              <Text>Tgl Trans</Text>
            </View>
            <View style={[styles.tableHeader, {width:'50px'}]}>
              <Text>Bukti</Text>
            </View>
            <View style={[styles.tableHeader, {width:'150px'}]}>
              <Text>Uraian</Text>
            </View>
            <View style={[styles.tableHeader, {width:'30px'}]}>
              <Text>Kode</Text>
            </View>
            <View style={[styles.tableHeader, {width:'40px'}]}>
              <Text>Sandi</Text>
            </View>
            <View style={[styles.tableHeader, {width:'70px'}]}>
              <Text>Debet</Text>
            </View>
            <View style={[styles.tableHeader, {width:'70px'}]}>
              <Text>Kredit</Text>
            </View>
            <View style={[styles.tableHeader, {width:'70px', borderRightWidth: 0}]}>
              <Text>Saldo</Text>
            </View>
          </View>

          <View  style={styles.Row}>
            <View style={[styles.tableCell, {width:'480px'}]}>
              <Text>Saldo Awal</Text>
            </View>
            <View style={[styles.tableCell, {width:'70px', borderRightWidth: 0}]}>
              <Text style={{ textAlign: "right" }}>{new Intl.NumberFormat('id-ID').format(saldoawal)}</Text>
            </View>
          </View>

          {/* Data tabel */}
          {generateTableData()}

          <View style={styles.Row}>
            <View style={[styles.tableCell, {width:'340px', borderBottomWidth: 0}]}>
              <Text>JUMLAH</Text>
            </View>
            <View style={[styles.tableCell, {width:'70px', borderBottomWidth: 0}]}>
              <Text style={{ textAlign: "right" }}>{new Intl.NumberFormat('id-ID').format(debet)}</Text>
            </View>
            <View style={[styles.tableCell, {width:'70px', borderBottomWidth: 0}]}>
              <Text style={{ textAlign: "right" }}>{new Intl.NumberFormat('id-ID').format(kredit)}</Text>
            </View>
            <View style={[styles.tableCell, {width:'70px', borderRightWidth: 0, borderBottomWidth: 0}]}>
              <Text style={{ textAlign: "right" }}>{new Intl.NumberFormat('id-ID').format(saldoakhir)}</Text>
            </View>
          </View>

        </View>
        <View style={styles.textContainer}>
        <Text style={{textAlign:"center", fontSize:"7"}}> <Image src={qrCodeUrl} style={styles.image}/>{'\n'}Dokumen ini{'\n'}sudah terverifikasi secara elektronik oleh Sistem Layanan Balance Inquiry{'\n'}PT BPR BANK BOYOLALI (PERSERODA)</Text>
        </View>
        <PageFooter />
      </Page>
    </Document>
      </PDFViewer>
    );
  }

// Stylesheet untuk PDF
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 8,
    padding: 40,
    paddingBottom: 50,
  },

  section: {
      margin: 10,
      padding: 10,
  },
  viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
  },

  Row: {
    flexDirection: "row",
    marginBottom: 2,
  },
  h3: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center" ,
  },

  table: { display: 'table', width: '100%', borderStyle: 'solid', borderWidth: 1,  overflow: 'wrap'  },
  tableRow: { flexDirection: 'row' },
  tableHeader: { backgroundColor: '#f0f0f0', width: '100%', padding: 5, borderBottom: '1px solid black', borderRight: '1px solid black', textAlign: 'center', borderTop: '1px solid black',},
  tableCell: { padding: 4, borderBottom: '1px dotted black', borderRight: '1px dotted black' },

  image: {
    width: 70,
    height: 70,
  },

  textContainer:{
    left: '10px',
  }
});



  export default PrintMutasi;

  