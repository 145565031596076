import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Users from "./pages/Users";
import Mutasi from "./pages/Mutasi";
import Rekening from "./pages/Rekening";
import CekMutasi from "./pages/CekMutasi";
import ConfigApp from "./pages/ConfigApp";
import PrintMutasi from './components/PrintMutasi';
import { LogOut, reset } from "./features/authSlice";
import { useDispatch } from "react-redux";


function App() {
  const dispatch = useDispatch();
useEffect(() => {
 
  let value = sessionStorage.getItem("islogin");
  if(!value){
    dispatch(LogOut())
    dispatch(reset()) 
  }
  }, []);


   return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/rekening" element={<Rekening />} />
          <Route path="/mutasi" element={<Mutasi />} />
          <Route path="/cekmutasi" element={<CekMutasi />} />
          <Route path="/configapp" element={<ConfigApp />} />
          <Route path="/printmutasi/:norek/:tgl_awal/:tgl_akhir/:uuid" element={<PrintMutasi />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
